import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
  Label,
  ModalHeader,
  Input,
} from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';

const ModalRecibo = ({ isVisible, setIsVisible, nuevos, setNuevos, matricula}) => {
  // Obtener la fecha actual-..SS
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const empresaId = JSON.parse(localStorage.getItem('enrollment')).headquarter;
  const [fecha, setFecha] = useState('');
  const [forma, setForma] = useState('Contado Efectivo');
  const [idForma, setIdForma] = useState(0);
  const [importe, setImporte] = useState(0);
  const [concepto, setConcepto] = useState('');
  const [periodico, setPeriodico] = useState(false);
  const [descuento, setDescuento] = useState(0);
  const [cuotas, setCuotas] = useState(1);
  const [tipo, setTipo] = useState(0)
  const [data, setData] = useState([]);
  const [dataType, setDataType] = useState([]);
  const userSession = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_NODE_API}cargarMetodosPago?headquarter=CEDESCA&userEmail=${userSession.profileObj.email}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
        setData(actualData);
      })
      .catch((error) => {
        sessionStorage.setItem('apiError', error);
        localStorage.removeItem('user');
        window.location.href = '/';
      });

      fetch(
        `${process.env.REACT_APP_NODE_API}cargarListaTipoCliente?headquarter=${empresaId}&userEmail=${userSession.profileObj.email}`,
        {
          method: 'get',
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
            'content-type': 'application/json',
          },
        },
      )
        .then((response) => response.json())
        .then((actualData) => {
          // Crear el nuevo objeto
const nuevoObjeto = { id: 0, nombre: 'Alumno' };

// Añadir el nuevo objeto al inicio del arreglo
actualData.unshift(nuevoObjeto);
          setDataType(actualData);
        })
        .catch((error) => {
          sessionStorage.setItem('apiError', error);
          localStorage.removeItem('user');
          window.location.href = '/';
        });
  }, []);

  const handleModalState = () => {
    setIsVisible(!isVisible);
  };

  const handleFecha = (value) => {
    const fechad = moment.utc(value).toDate();
    const formattedDate = moment(fechad).format('DD/MM/YYYY');
    setFecha(formattedDate);
  };

  const handleOnChangeImporte = (e) => {
    setImporte(e.target.value);
  };
  const handleOnChangeCuotas = (e) => {
    setCuotas(e.target.value);
  };
  const handleOnChangeDescuento = (e) => {
    setDescuento(e.target.value);
  };
  const handleOnChangePeriodico = () => {
    setPeriodico(!periodico);
  };
  const handleOnChangeConcpeto = (e) => {
    setConcepto(e.target.value);
  };

  const handleOnChangeForma = (e) => {
    const [id, descripcion] = e.target.value.split('-');
    setForma(descripcion);
    setIdForma(parseInt(id, 10));
  };

  const handleOnChangeTipo = (e) => {
   setTipo(e.target.value)
  console.log(e.target.value, 'hey')
  };

  const handleAdd = () => {
    const nuevosRecibos = [];
    for (let i = 0; i < cuotas; i++) {
      const nuevaFechaVencimiento = moment(fecha, 'DD/MM/YYYY').add(i, 'months');
      const formattedFechaVencimiento = nuevaFechaVencimiento.format('DD/MM/YYYY');
      const mesNombre = nuevaFechaVencimiento.format('MMMM');
      const importeActual = importe - (importe * descuento / 100)
      let tipoCliente = 1

      if (tipo > 0 ){
        tipoCliente = 2
        
      }
      const nuevoRecibo = {
        NUM_Recibo: 'xxx',
        Num_factura: 'xxx',
        Concepto: periodico ? `Cuota mes de ${mesNombre}` : concepto,
        Fecha_Emision: `${day}/${month}/${year}`,
        Fecha_vencimiento: formattedFechaVencimiento,
        Fecha_cobro: 'Sin definir',
        Neto: periodico ? (importeActual/cuotas).toFixed(2) : importeActual,
        Bruto: periodico ? (importeActual/cuotas).toFixed(2) : importeActual,
        Tipo_cliente: 'A',
        tipoCliente,
        ccodcli: tipoCliente === 1 ? matricula : tipo,
        Forma_de_pago: forma,
        Estado: 'Pendiente',
        idPago: idForma,
        descuento,
        memo: `Aplicado descuento del ${descuento}%`,
      };
      console.log(nuevoRecibo, 'hey')
      nuevosRecibos.push(nuevoRecibo);
    }
    setNuevos([...nuevosRecibos, ...nuevos]);
    setIsVisible(false);
  };

  return (
    <div>
      <Modal isOpen={isVisible} centered size="lg">
        <ModalHeader>Añadir recibo</ModalHeader>
        {data.length > 0 && (
          <ModalBody>
            <Form>
              <Row>
                <Col md="4">
                  <Label>Fecha de vencimiento</Label>
                </Col>
                <Col md="5">
                  <Label>Forma de pago</Label>
                </Col>
                <Col md="3">
                  <Label>Importe</Label>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md="4">
                  <Datetime
                    id="fecha"
                    name="fecha"
                    timeFormat={false}
                    onChange={handleFecha}
                    dateFormat
                  />
                </Col>
                <Col md="5">
                  <Input
                    type="select"
                    name="formaDePago"
                    id="formaDepago"
                    onChange={handleOnChangeForma}
                  >
                    {data.map((datos) => (
                      <option key={datos.id} value={`${datos.id}-${datos.descripcion}`}>
                        {datos.descripcion}
                      </option>
                    ))}
                  </Input>
                </Col>

                <Col md="3">
                  <Input type="number" name="importe" id="importe" onChange={handleOnChangeImporte} min="0" defaultValue={importe} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="4">
                  <Label>Descuento %</Label>
                  <Input type="number" name="descuento" id="descuento" onChange={handleOnChangeDescuento} min="0" max="100" defaultValue={descuento} />
                  {descuento < 0 || descuento > 100 ? <span className="text-danger tiny">Descuento entre el 0% y el 100%</span> : ''}
                </Col>
                <Col md="5">
                  <Label>Periódico</Label>
                  <div className="form-check">
                    <Input className="form-check-input" type="checkbox" name="periodico" id="periodico" onChange={handleOnChangePeriodico} />
                    <Label className="form-check-label" htmlFor="periodico">
                      Sí
                    </Label>
                  </div>
                </Col>
                <Col md="3">
                  <Label>Número de cuotas</Label>
                  <Input type="number" name="cuotas" id="cuotas" onChange={handleOnChangeCuotas} min="1" disabled={!periodico} />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>Tipo de cliente</Label>
                </Col>
              </Row>
              <Row className="mb-2">
              <Col md="5">
              
                  <Input
                    type="select"
                    name="tipoCliente"
                    id="tipoCliente"
                    onChange={handleOnChangeTipo}
                  >
                    {dataType.map((datos) => (
                      <option key={datos.id} value={datos.id}>
                        {datos.nombre}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>Concepto</Label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="12">
                  <Input
                    type="text"
                    name="concepto"
                    id="concepto"
                    onChange={handleOnChangeConcpeto}
                    disabled={periodico}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  Importe con descuento aplicado: { importe - (importe * descuento / 100)} €
                </Col>
              </Row>
            </Form>
          </ModalBody>
        )}

        <ModalFooter>
          <Button
            color="success"
            disabled={
              importe === '0' || importe === 0 || (periodico === false && concepto.length === 0) || fecha.length === 0 || descuento < 0 || descuento > 100
            }
            onClick={handleAdd}
          >
            Añadir
          </Button>
          <Button color="danger" onClick={handleModalState}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ModalRecibo.propTypes = {
  isVisible: PropTypes.any,
  setIsVisible: PropTypes.any,
  nuevos: PropTypes.any,
  setNuevos: PropTypes.any,
  matricula: PropTypes.any
};

export default ModalRecibo;
